import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ngx-active-cell',
  styleUrls: ['./active-cell.component.scss'],
  template: `
    <nb-icon *ngIf="value === true"
             class="icon active"
             icon="checkmark-square-2"
             pack="eva"
    ></nb-icon>
    <nb-icon *ngIf="value === false"
             class="icon inactive"
             icon="close-square"
             pack="eva"
    ></nb-icon>
  `,
})
export class ActiveCellComponent implements OnInit {

  @Input()
  value?: boolean;

  constructor() { }

  ngOnInit() {
  }
}
