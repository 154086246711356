<nb-card>
  <nb-card-header *ngIf="type === 'decline'">Вернуть установщику на доработку</nb-card-header>
  <nb-card-header *ngIf="type === 'irrelevant'">Неактуальность заявки</nb-card-header>
  <nb-card-body>
    <div>
      Причина
    </div>
    <input id="industry" type="text" nbInput fullWidth placeholder="Причина"  [(ngModel)]="reason" style="margin-top: 10px"/>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton (click)="close()">Закрыть</button>
    <button nbButton (click)="submit()" [disabled]="!this.reason" style="margin-left: 10px">
      <ng-container *ngIf="type === 'decline'">Вернуть</ng-container>
      <ng-container *ngIf="type === 'irrelevant'">Отметить неактуальной</ng-container>
    </button>
  </nb-card-footer>
</nb-card>
