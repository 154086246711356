import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'blobToImageSrc',
})
export class BlobToImageSrcPipe implements PipeTransform {
    public transform(blob: Blob | null): Promise<string | ArrayBuffer | null> {
        if (!blob) {
            return Promise.resolve(null)
        }

        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = (): void => {
                resolve(reader.result)
            }
            reader.onerror = reject
            reader.readAsDataURL(blob)
        })
    }
}
