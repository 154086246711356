import { NbMenuItem } from '@nebular/theme';

export const LOGOUT_MENU_ITEM: NbMenuItem = {
    title: 'Выйти',
    icon: 'log-out-outline',
    link: '/auth/login',
    pathMatch: 'full',
}

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Склады POSM',
    icon: 'car-outline',
    link: '/pages/warehouses/list',
    pathMatch: 'prefix',
    home: true,
  },
  {
    title: 'Торговые точки',
    icon: 'shopping-cart-outline',
    link: '/pages/stores/list',
    pathMatch: 'prefix',
  },
  {
    title: 'Программы',
    icon: 'book-open-outline',
    link: '/pages/programs/list',
    pathMatch: 'prefix',
  },
  {
    title: 'POSM',
    icon: 'color-palette-outline',
    link: '/pages/posm/list',
    pathMatch: 'prefix',
  },
  {
    title: 'Заявки',
    icon: 'file-text-outline',
    link: '/pages/orders/list',
    pathMatch: 'prefix',
  },
  // {
  //   title: 'Пользователи системы',
  //   group: true,
  //   pathMatch: '/pages/all',
  //   data: {
  //     auth: true,
  //   },
  // },
  {
    title: 'Пользователи',
    icon: 'person-outline',
    link: '/pages/users/list',
    pathMatch: 'prefix',
    data: {
      auth: true,
    },
  },
  {
    title: 'Клиенты',
    icon: 'people-outline',
    link: '/pages/clients/list',
    pathMatch: 'prefix',
    data: {
      auth: true,
    },
  },
  {
    title: 'Доп. работы',
    icon: 'file-add-outline',
    link: '/pages/extra-works/list',
    pathMatch: 'prefix',
    data: {
      auth: true,
    },
  },
  LOGOUT_MENU_ITEM,
];
