import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import { LOGOUT_MENU_ITEM, MENU_ITEMS } from './pages-menu';
import { AuthService } from '../services/auth/auth.service';
import { Subscription } from 'rxjs';
import { NbMenuItem, NbMenuService } from '@nebular/theme';
import { isMobileDevice, getUniqueName } from '../../utils';
import { tap } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Environment, UserRole } from '../../types';
import { cloneDeep } from 'lodash';

export const DOWNLOAD_ORDER_ANALYTICS_LINK: NbMenuItem = {
  title: 'Заявки Excel',
  link: '/pages/report',
  icon: 'download',
  pathMatch: "prefix"
};

const ANALYTICS_GROUP: NbMenuItem = {
  title: 'Аналитика',
  group: true
};

@Component({
  selector: 'ngx-pages',
  styleUrls: ['pages.component.scss'],
  template: `
      <ngx-one-column-layout>
          <nb-menu #nbmenu [items]="menu"></nb-menu>
          <router-outlet></router-outlet>
      </ngx-one-column-layout>
  `,
})
export class PagesComponent implements OnInit, OnDestroy {
  @ViewChild('nbmenu', { static: false, read: ElementRef }) menuElement: ElementRef

  private subs: Subscription[] = [];
  public menu: NbMenuItem[] = [];

  private isDownloadingAnalytics = false

  constructor(
    private authService: AuthService,
    private menuService: NbMenuService,
    private env: Environment,
    private http: HttpClient,
  ) {
  }

  private getDownloadExcelMenuItem(): HTMLElement | null {
    const selector = `li.menu-item > a[title='${DOWNLOAD_ORDER_ANALYTICS_LINK.title}']`
    const item = this.menuElement.nativeElement.querySelector(selector) as HTMLElement
    return item
  }

  private downloadAnalytics = (url: string) => {
    if (this.isDownloadingAnalytics) return

    const menuItem = this.getDownloadExcelMenuItem()
    menuItem.classList.add('disabled')
    this.isDownloadingAnalytics = true

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${this.authService.getToken()}`,
    });

    this.http.get(
      `${this.env.apiUrl}${url}`,
      {
        responseType: 'blob',
        headers,
      },
    ).subscribe(r => {
      const link = document.createElement('a');
      const href = URL.createObjectURL(r);

      document.body.appendChild(link);

      link.href = href;
      link.target = '_blank';
      link.download = `Аналитика-по-заявкам-${getUniqueName()}.xlsx`;
      link.click();

      // Clear
      window.URL.revokeObjectURL(href);
      link.remove();

      this.isDownloadingAnalytics = false
      menuItem.classList.remove('disabled')
    });
  }

  private addDownloadOrderAnalyticsLink = () => {
    if (!this.menu.find(i => i.title === DOWNLOAD_ORDER_ANALYTICS_LINK.title)) {
      this.menu = this.menu.filter(item => item.link !== LOGOUT_MENU_ITEM.link);

      this.menu = [
        ...this.menu,
        ANALYTICS_GROUP,
        DOWNLOAD_ORDER_ANALYTICS_LINK,
        LOGOUT_MENU_ITEM,
      ];
    }
  }

  public ngOnInit(): void {
    this.authService.loadCurrentUser().pipe(
      tap(() => this.menu = cloneDeep(MENU_ITEMS)),
    ).subscribe(() => {
      this.subs = [

        this.menuService.onItemClick().subscribe(event => {
          if (event.item.link && event.item.link.includes('/auth/login')) {
            this.authService.logout();
          }
        }),
        this.authService.role.subscribe(role => {
          switch (role) {
            case UserRole.CLIENT:
              if (isMobileDevice()) {
                this.menu = [
                  {
                    title: 'Заявки',
                    icon: 'file-text-outline',
                    link: '/pages/orders/list',
                    pathMatch: 'prefix',
                  },
                  {
                    title: 'Выйти',
                    icon: 'log-out-outline',
                    link: '/auth/login',
                    pathMatch: 'full',
                  },
                ];
              } else {
                this.menu = [
                  {
                    title: 'Заявки',
                    icon: 'file-text-outline',
                    link: '/pages/orders/list',
                    pathMatch: 'prefix',
                  },
                  {
                    title: 'Выйти',
                    icon: 'log-out-outline',
                    link: '/auth/login',
                    pathMatch: 'full',
                  },
                ];
              }
              break;
            case UserRole.SENIOR_INSTALLER:
              this.menu = [
                ...this.menu.filter(item => {
                  const urls = ['programs', 'posm', 'warehouses', 'stores'];

                  return item.link && !urls.some(url => item.link.includes(url));
                }),
              ];

              break;
            case UserRole.INSTALLER:
              this.menu = [
                {
                  title: 'Мой маршрут',
                  icon: 'compass-outline',
                  link: '/pages/route/my',
                  pathMatch: 'prefix',
                },
                ...this.menu.filter(item => {
                  const urls = ['orders', 'warehouse', 'programs', 'posm', 'stores'];

                  return item.link && !urls.some(url => item.link.includes(url));
                }),
              ];
              break;
            case UserRole.COORDINATOR:
              this.addDownloadOrderAnalyticsLink();
              break;
            case UserRole.ACTION:
              this.menu = [
                {
                  title: 'Заявки',
                  icon: 'file-text-outline',
                  link: '/pages/orders/list',
                  pathMatch: 'prefix',
                },
                {
                  title: 'Выйти',
                  icon: 'log-out-outline',
                  link: '/auth/login',
                  pathMatch: 'full',
                }
              ];
              break;
            case UserRole.SUPER_ADMIN:
              this.addDownloadOrderAnalyticsLink();
              break
          }
        }),

        this.authService.isAdmin.subscribe(isAdmin => {
          if (!isAdmin) {
            this.menu = this.menu.filter(({ data }) => !data);
          }
        }),
      ];
    });
  }


  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
