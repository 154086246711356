/*
 Local environment variables
 for local development
*/

export const environment = {
  production: true,
  version: (window as any).sVersion,
  apiUrl: `https://techmerch-demo.cloudstaffing.ru/api`,
  name: 'demo',
};
