import { Component, OnInit, Input } from '@angular/core';
import { OrderResponse } from '../../../../../types';

@Component({
  selector: 'ngx-created-by-cell',
  template: '{{renderValue}}',
})
export class CreatedByCellComponent implements OnInit {

  @Input() rowData: OrderResponse;

  renderValue = '';
  ngOnInit() {
    const { first_name, last_name, email } = this.rowData.created_by;

    this.renderValue = (first_name ? first_name + ' ' : '').concat(
      last_name ? last_name + ' ' : '',
      email ? `(${email})` : '');   //`${first_name} ${last_name} (${email})`;
  }
}
