import { Injectable } from '@angular/core';
import get from 'lodash/get';
import { Router } from '@angular/router';

import { isMobileDevice } from '../../../utils';
import { UserRole, UserResponse } from '../../../types';


@Injectable({ providedIn: 'root' })
export class RedirectService {

  constructor(private router: Router) {
  }

  public redirectDesktop(role: UserRole): void {
    switch (role) {
      case UserRole.COORDINATOR:
      case UserRole.CLIENT:
      case UserRole.ACTION:
        this.router.navigate(['/pages/orders/list']).then()
        break
      case UserRole.INSTALLER:
        this.router.navigate(['/pages/route/my']).then()
        break
      case UserRole.SUPER_ADMIN:
      case UserRole.SENIOR_INSTALLER:
        this.router.navigate(['/pages/orders/list']).then()
    }
  }

  public redirect(user: UserResponse): void {
    const role = get(user, 'role');
    if (isMobileDevice()) {
      console.log('mobile device redirect')
      if (role !== UserRole.CLIENT && role !== UserRole.INSTALLER && role !== UserRole.ACTION) {
        this.router.navigate(['/only-web']).then()
      } else if (role === UserRole.CLIENT || role === UserRole.ACTION) {
        this.router.navigate(['/pages/orders/list']).then()
      } else if (role === UserRole.INSTALLER) {
        this.router.navigate(['/pages/route/my']).then()
      }
    } else {
      console.log('desktop device redirect')
      this.redirectDesktop(role)
    }
  }
}
