<div class="row">
  <div class="col-12 header-form">
    <button class="add-button" nbButton status="danger" (click)="handleCreate()">
      <nb-icon class="form-button-icon" icon="plus" pack="eva"></nb-icon>
    </button>
    <form class="search-form" [formGroup]="form" (ngSubmit)="handleSearch()">
      <input
        nbInput
        fieldSize="large"
        fullWidth
        class="form-input"
        placeholder="Поиск по ID заявки"
        formControlName="search"
        type="text"
      />
    </form>
  </div>
  <div
    infiniteScroll
    [infiniteScrollDistance]="infiniteScrollDistance"
    [infiniteScrollThrottle]="infiniteScrollThrottle"
    (scrolled)="loadNext()"
    [class.loading]="loading" class="loader-container col-md-12" [fromRoot]="true"
    >
    <nb-accordion>
      <nb-accordion-item class="list-item" *ngFor="let item of data">
        <nb-accordion-item-header>
          <div class="header">
            <span>{{ item | getName: type }}</span>
          </div>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <ngx-mobile-order-list-item
            (goTo)="handleGoToDetail($event)"
            [item]="item"
            *ngIf="type === 'orders'">
          </ngx-mobile-order-list-item>
          <ng-container *ngIf="type === 'routes'">
            <div class="content">
              <p>
                {{item | json}}
              </p>
            </div>
          </ng-container>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
  </div>
</div>
