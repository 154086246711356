import { Component, OnInit, Input } from '@angular/core';
import { OrderV2 } from '../../../../../types/order-v2';

@Component({
  selector: 'ngx-city-cell',
  template: '{{renderValue}}',
})
export class CityCellComponent implements OnInit {
  @Input() value: string;
  @Input() rowData: OrderV2;

  renderValue = '';

  public ngOnInit(): void {
    if (!this.rowData) return;

    const { store, is_delivery, delivery_from, delivery_to } = this.rowData;

    if (!is_delivery) {
      this.renderValue = store?.city?.name || '';
      return;
    }

    if (is_delivery) {
      this.renderValue = delivery_from?.city?.name === delivery_to?.city?.name
        ? delivery_from?.city?.name
        : `${delivery_from?.city?.name}, ${delivery_to?.city?.name}`;
    }
  }
}
