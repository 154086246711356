import { Component, Input, OnInit } from '@angular/core';
import { OrderV2 } from '../../../../../types/order-v2';

type NameAdress = { name: string; address_name: string };

@Component({
  selector: 'ngx-address-cell',
  templateUrl: './address-cell.component.html',
  styleUrls: ['./address-cell.component.scss'],
})
export class AddressCellComponent implements OnInit {
  @Input() public rowData: OrderV2;

  @Input() public value: NameAdress[] = [{ name: '', address_name: ''}];

  public ngOnInit(): void {
    if (!this.rowData) return;

    const { store, is_delivery, delivery_from, delivery_to } = this.rowData;

    if (!is_delivery && store) {
      this.value = [store];
      return;
    }

    if (is_delivery && delivery_from && delivery_to) {
      this.value = [delivery_from, delivery_to];
    }
  }
}
