import { Pipe, PipeTransform } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { Observable } from 'rxjs/Observable'
import { catchError, map, share } from 'rxjs/operators'
import { environment } from '../../../../environments/environment';
import { of } from 'rxjs';

const API_URL = environment.apiUrl;
const imageUrlCache: { [id: string]: Observable<SafeUrl> } = {}

@Pipe({
  name: 'getImageByIdWithToken'
})
export class GetImageByIdWithTokenPipe implements PipeTransform {


  private getFullUrl(id: number | string): string {
    return `${API_URL}/images/${id}/show`
  }

  private readonly fallbackImage = '/assets/images/nophoto.jpg'

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {
  }

  transform(id: number | string): Observable<SafeUrl> {

    if (!imageUrlCache[id]) {
      imageUrlCache[id] = this.getImage(id).pipe(share())
    }

    return imageUrlCache[id]
  }

  private getImage(id: number | string): Observable<SafeUrl> {
    return this.http
      .get(this.getFullUrl(id), { responseType: 'blob' })
      .pipe(
        map(val => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))),
        catchError(() => of(this.sanitizer.bypassSecurityTrustUrl(this.fallbackImage))),
      )
  }
}
