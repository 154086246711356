import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMenuService, NbSidebarService } from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { MENU_ITEMS } from '../../../pages/pages-menu';
import { isMobileDevice, USER_ROLE_TRANSLATIONS } from '../../../../utils';
import { AuthService } from '../../../services/auth/auth.service';
import { UserResponse } from '../../../../types';
import { DOWNLOAD_ORDER_ANALYTICS_LINK } from '../../../pages/pages.component';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  public isMobile = false;

  public userPictureOnly: boolean = false;
  public user = '';
  public userRole = '';
  public userMenu = [{ title: 'Profile' }, { title: 'Выйти' }];
  public pageTitle: string;

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private layoutService: LayoutService,
              private authService: AuthService,
              private router: Router,
  ) {
    this.isMobile = isMobileDevice()
  }

  private setTitleByUrl(url: string) {
    let key = '';

    if (url.includes('pages')) {
      key = url.split('/pages/')[1].split('/')[0];
    }

    const currentMenuItem = [...MENU_ITEMS, DOWNLOAD_ORDER_ANALYTICS_LINK].find(({ link }) =>
      link.includes(key));

    const defaultTitle = key === 'route' ? 'Маршрут' : 'Склады POSM';

    this.pageTitle = currentMenuItem ? currentMenuItem.title : defaultTitle;
  }

  public ngOnInit(): void {
    this.setTitleByUrl(this.router.url);

    this.authService.user.pipe(filter(Boolean))
      .subscribe((u: UserResponse) => {
        const midName = u?.middle_name?.trim().slice(0, 1).toUpperCase() || ''
        this.user = `
          ${u.last_name} ${u.first_name.slice(0, 1).toUpperCase()}. ${midName ? midName + '.' : ''}
        `

        this.userRole = `(${USER_ROLE_TRANSLATIONS[u.role]})`
      })

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
      if ((event as any).url) {
        this.setTitleByUrl((event as any).url);
      }
    });


    this.menuService.onItemClick()
      .subscribe(() => {
        if (isMobileDevice()) {
          this.sidebarService.toggle(false, 'menu-sidebar'); // Hide sidebar
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
