export interface LoginRequest {
  username: string;
  password: string;
}

export interface TokenResponse {
  token: string;
}

export interface IdResponse {
  id: number;
}

export interface IdName {
  id: number;
  name: string;
}

export interface ExtraWorksWithCount {
  work_type: {
    id: number;
  };
  quantity: number;
}

export interface ExtraWorksWithCountDetailed extends ExtraWorksWithCount {
  work_type: {
    id: number;
    name: string;
  };
}

export interface BagItem {
  posm: IdResponse;
  quantity: number
  count: number
  name: string
  images: any[]
  workType?: string;
  plan_time_per_one_posm: number | null

}

export interface BagItemWithPosmName {
  posm: IdName;
  quantity: number
}

export type V2ServerError = TDetailError | TOtherError | TNonFieldError

export type TDetailError   = { detail: string }
export type TOtherError    = { [Key: string]: string[] }
export type TNonFieldError = { non_fields_errors: string[] }

export interface Bag {
  items: BagItem[] | null;
}

/**
 * @deprecated The interface is deprecated. To be removed
 */
export interface BagBlankRun {
  items: BagItemWithBlankRun[] | null;
}

export interface BlankRunPayload {
  posm_bag_item_id: number;
  comment: string;
}

/**
 * @deprecated The interface is deprecated. To be removed
 */
interface BagItemWithBlankRun extends BagItem {
  id: number;
  blank_run: boolean;
  blank_run_comment: string;
  fact_quantity: number;
  actualQuantity: number;
}

export interface BagItem {
  posm: IdResponse;
  quantity: number
  fact_quantity: number
  workType?: string;
}


export interface BagWithPosmName {
  items: BagItemWithPosmName[];
}

export interface AddressRequest {
  address_lat?: number;
  address_long?: number;
  address_name: string;
  client: IdResponse;
  name: string;
  warehouse_code?: string;
  city?: IdResponse;
  tariff_zone?: IdResponse;
  secondary_city?: IdResponse;
  bag?: Bag;
}

export enum UserRole {
  CLIENT = 'CLIENT',
  COORDINATOR = 'COORDINATOR',
  SENIOR_INSTALLER = 'SENIOR_INSTALLER',
  INSTALLER = 'INSTALLER',
  SUPER_ADMIN = 'SUPER_ADMIN',
  ACTION = 'ACTION',
}

export interface UserResponse {
  id: number;
  first_name: string;
  last_name: string;
  middle_name: string;
  email: string;
  role: UserRole;
  created_at: string;
  rights: string[];
  updated_at: string;
  created_by_user: IdName;
  updated_by_user: IdName;
  city?: IdName;
  add_regions?: string;
  client: IdName | null;
  create_for_id: number;
}

export interface UserRequest {
  id?: number;
  client: Id;
  city: Id;
  first_name: string;
  last_name: string;
  middle_name: string;
  email: string;
  password?: string;
  role: UserRole;
  rights: number[]
}

export interface ClientsRequest {
  name: string;
  industry: string;
  url: string;
}

export interface ExtraWorksRequest {
  name: string;
  is_active: boolean;
}

export interface RetailChain {
  name: string
  id: number
  client: {
    name: string
    id: number,
  }
}

export interface TariffZone {
  id: number
  zone: string
  client: {
    id: number,
  }
}

export interface ServerResponse {
  message: string
}

export interface ClientsResponse {
  name: string;
  industry: string;
  url: string;
  id: number;
  created_at: string;
  updated_at: string;
  created_by_user: IdName;
  updated_by_user: IdName;
}

export interface ExtraWorksResponse {
  name: string;
  id: number;
  is_active: boolean;
}


export interface WarehouseGetResponse {
  name: string;
  client: IdName;
  address_name: string;
  address_lat: number;
  address_long: number;
  id: number;
  created_at: string;
  updated_at: string;
  created_by_user: IdName;
  updated_by_user: IdName;
  warehouse_code: string;
  city?: IdResponse;
  tariff_zone?: IdResponse;
  secondary_city?: IdResponse;
  bag: Bag;
}

export interface StoresResponse {
  name: string;
  id: number;
  title: string;
  created_at: string;
  updated_at: string;
  created_by_user: IdName;
  updated_by_user: IdName;
  retail_chain: IdName;
  tariff_zone: IdName;
  point_code: string;
  external_code: string;
  client: IdName;
  address_name: string;
  address_lat: number;
  address_long: number;
  label?: string;
  city: IdName;
  secondary_city: IdName;
}

export type DeviceNotification = 'android' | 'ios';

export interface PushNotificationRequest {
  device: DeviceNotification;
  token: string;
}

export type TypeMessageFromDevice = 'push' | 'geoLocation' | 'camera';

export type PushMessage = 'PUSH_REGISTER_ANDROID' | 'PUSH_REGISTER' | 'PUSH_PAYLOAD' | 'PUSH';

export interface PushGeolocationRequest {
  lat: number;
  long: number;
}

export interface ItemBag {
  posm: IdName;
  quantity: number;
}

export interface Bags {
  items: ItemBag[];
}

export interface PagedResponseV2<T>{
  count: number
  next: T
  previous: T
  results: T[]
}

export interface OrderResponse {
  is_delivery?: boolean;
  deliver_from_entity_class?: any;
  deliver_from_entity_id?: any;
  deliver_to_entity_class?: any;
  deliver_to_entity_id?: any;
  deadline: string;
  program: IdName;
  executor: Executor;
  store: StoresResponse;
  status: string;
  executed_at: string;
  works_type: string;
  install_bag: Bag;
  uninstall_bag: Bag;
  repair_bag: Bag;
  in_progress_bag: Bag;
  id: number;
  created_at: string;
  updated_at: string;
  created_by: UserResponse;
  updated_by_user: UserResponse;
  client: IdName;
  extra_works: ExtraWorksWithCountDetailed[];
  name: string;

  decline_reason: string;
  allowed_to_comment: boolean;

  creator_contact_information: string;
  company_representative_contact_information: string;
  creator_comment: string;
  senior_installer_comment: string;

  // NOTE: delivery info
  delivery_bag: Bag;
  delivery_from_entity_name: string;
  delivery_from_entity_id: number;

  delivery_to_entity_name: string;
  delivery_to_entity_id: string;

  // NOTE: whether this order is approved
  // by user created it
  approved_by_creator: boolean;

  created_for: UserRequest;

  // NOTE: needed to check whether it was manually done
  // before it had route created for it
  manually_done: boolean;

  // Additional field that does not exist in actual response
  // but needed in client
  minutes_to_complete: string;

  allowed_to_add_images_on_site: boolean;
}

export type WorkType = 'INSTALL'| 'UNINSTALL' | 'REPAIR';

export interface OrderRequest {
  // name: string;
  client: IdResponse;
  program?: IdResponse;
  install_bag?: Bag;
  uninstall_bag?: Bag;
  repair_bag?: Bag;
  store: IdResponse;
  deadline: string;
  creator_contact_information: string;
  company_representative_contact_information: string;
  comment?: string;
  installer?: { id: number };
}

export interface Field {
  id: string;
  fieldName: string;
}

export interface ProgramsResponse {
  name: string;
  id: number;
  created_at: string;
  updated_at: string;
  created_by: UserResponse;
  updated_by_user: UserResponse;
  client: IdName;
}

export interface ProgramsRequest {
  name: string;
  client: Id;
  posms: Id[];
}

export interface Id {
  id: number;
}

export interface PosmResponse {
  id: number;
  created_at: string;
  updated_at: string;
  label?: string;
  name: string;
  images: Id[];
  client: IdName;
  sku?: string;
  uninstall_time: number;
  repair_time: number;
  install_time: number;
  isFinished?: boolean;
  blank_run: boolean;
  blank_run_comment: string;
}

export interface PosmWithCount extends PosmResponse {
  count?: number;
  worksType?: {id: string, name: string; };
}

export interface PosmRequest {
  client: Id;
  images: Id[];
  name: string;
  sku: string;
  uninstall_time: number;
  repair_time: number;
  install_time: number;
}

export interface Executor {
  id: number;
  first_name: string;
  last_name: string;
  middle_name: string;
}

export type StatusType = 'NEW' | 'IN_WORK' | 'ROUTE_CREATED' | 'DONE' | 'IRRELEVANT';

export interface IExecutionPart {
  id: number
  bag: BagBlankRun
  work_type: string
  work_type_name: string
  document_pack: {
    comment: string
    images: number[]
  }
  installer: IdResponse | null
  order?: OrderResponse
}

/**
 * @deprecated The interface is deprecated in favor to Order V2
 */
export interface RoutePart {
  discr: string;
  bag: BagBlankRun;
  remaining_timer_bag: Bag;
  finished_timer_bag: Bag;
  installer: IdResponse;
  minutes_to_complete?: number;
  minutes?: string;
  completed: boolean;
  isStarted?: boolean;
  id: number;
  created_at: string;
  updated_at: string;
  created_by_user: UserResponse;
  updated_by_user: UserResponse;
  entityCode: string;
  install_bag: Bag;
  uninstall_bag: Bag;
  repair_bag: Bag;
  is_delivery: boolean
  warehouse?: WarehouseGetResponse;
  store?: StoresResponse;
}

/**
 * @deprecated The interface is deprecated. Remove after review
 */
export interface ResourceRoutePart {
  parts: RoutePart[];
}

export type RoutePartType = 'route_part_take_from_warehouse' |
  'route_part_store_in_store' |
  'route_part_repair_in_store' |
  'route_part_take_from_store' |
  'route_part_store_in_warehouse';

export interface AddressFromModal {
  addressName: string;
  addressLat: number;
  addressLong: number;
}

export class Environment {
  production: boolean;
  version: string;
  apiUrl: string;
}

export class Comment {
  text: string;
  commenter: {
    full_name: string,
    roles: string[],
  };

  created_at: string;
}

export interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

export type TUploadedImages = {
  workType: string;
  file: File;
  id: number;
};
