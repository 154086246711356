import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import get from 'lodash/get';

import { environment } from '../../../environments/environment';
import { LoginRequest, TokenResponse, UserResponse, UserRole } from '../../../types';
import { WebViewHelper } from '../../../utils';

const API_URL = environment.apiUrl;
const TOKEN_KEY = 'ACTION_TOKEN';

interface ISettings {
  use_camera_for_photos: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _user$: BehaviorSubject<UserResponse> = new BehaviorSubject<UserResponse>(null);

  private _token$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private token;
  private userData;

  constructor(
    private http: HttpClient,
  ) { }

  get authToken() {
    return this._token$.asObservable();
  }

  get user(): Observable<UserResponse> {
    return this._user$.asObservable();
  }

  public get isAdmin(): Observable<boolean> {
    return this.user.pipe(
      map(user => get(user, 'role') === UserRole.SUPER_ADMIN),
    );
  }

  public get isInstaller(): Observable<boolean> {
    return this.user.pipe(
      map(user => get(user, 'role') === UserRole.INSTALLER),
    );
  }

  get role() {
    return this.user.pipe(
      map(user => get(user, 'role')),
    );
  }

  get roleId() {
    return this.user.pipe(
      map(user => ({role: get(user, 'role'), id: get(user, 'id')})),
    );
  }

  public get isSeniorInstaller(): Observable<boolean> {
    return this.role.pipe(map(role => role === UserRole.SENIOR_INSTALLER));
  }

  get isClient () {
    return this.role.pipe(
      map(role => role === UserRole.CLIENT),
    );
  }

  get isAction () {
    return this.role.pipe(
      map(role => role === UserRole.ACTION),
    );
  }

  get rights() {
    return this.user.pipe(
      map(user => user.rights),
    );
  }

  public get canAddStores() {
    return this.rights.pipe(
      withLatestFrom(this.isClient),
      map(([rights, isClient]) => !isClient || rights.includes('CAN_ADD_STORES')),
    );
  }

  //

  deleteAccount(payload: LoginRequest): Observable<any> {
    return this.http.post<Observable<any>>(`${API_URL}/users/deactivate`, payload)
  }

  useCameraForPhotos$(): Observable<boolean> {
    return this.http.get<ISettings>(`${API_URL}/settings`)
      .pipe(map(settings => settings.use_camera_for_photos))
  }

  loadCurrentUser(): Observable<UserResponse> {
    return this.http.get<UserResponse>(`${API_URL}/users/current`).pipe(
      map(response => {
        if (response) {
          this._user$.next(response);
          this.userData = response;
        }

        return response;
      }),
    );
  }

  login(payload: LoginRequest): Observable<TokenResponse> {
    return this.http.post<TokenResponse>(`${API_URL}/auth/login`, { ...payload }).pipe(
      map(response => {
        if (response && response.token) {
          this.setToken(response.token);

          WebViewHelper.saveAuthToken(response.token);
        }
        return response;
      }),
    );
  }

  public clearToken(): void {
    localStorage.removeItem(TOKEN_KEY)
  }

  public setToken(token?: string) {
    this.token = token;

    if (localStorage && token) {
      localStorage.setItem(TOKEN_KEY, token);
    }

    this._token$.next(token);
  }

  public isAuthenticated() {
    console.log('not authed', !!this.token, !!localStorage.getItem(TOKEN_KEY))
    console.log('data', this.token, localStorage.getItem(TOKEN_KEY))
    return !!this.token || (localStorage && !!localStorage.getItem(TOKEN_KEY));
  }

  public getToken() {
    return this.token || (localStorage && localStorage.getItem(TOKEN_KEY));
  }

  public getUserData(): UserResponse | null {
    return this.userData || null;
  }

  public getUserID(): number | undefined {
    return this.userData ? this.userData.id : undefined;
  }

  public logout() {
    WebViewHelper.saveAuthToken('NONE');

    if (localStorage) {
      localStorage.removeItem(TOKEN_KEY);
    }

    this.token = null;

    window.location.href = '/auth/login';
  }

}

